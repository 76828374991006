//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TriangleCustomRate from '$resources/images/triangle-custom-rate.png';

export default {
  filters: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE').format(Number(value).toFixed(2));
    }
  },
  props: {
    selected: Boolean,
    offer: Object,
    hiredPower: String,
    variant: {
      type: String,
      validator: v => ['common', 'without-wattage'].includes(v),
      default: 'common'
    }
  },
  data: () => ({
    TriangleCustomRate
  }),
  computed: {
    ratesDescription() {
      switch (this.offer.idTarifaPersonalizada) {
        case '3': // fija 2.0TD
          return [
            'Disfruta de un precio estable tus 24 horas al día sin pensar en horarios, usa tus electrodomésticos a la hora que quieras, se acabaron las preocupaciones.'
          ];
        case '2': // fija 3.0
          return [
            'En Energest pensamos en vosotros. Los que estáis día a día manteniendo vuestro negocio a flote, los que os preocupáis por vuestros clientes. Por ello, queremos ofrecer la tarifa más ajustada a vuestro consumo ya seáis pequeñas o grandes empresas. Os asesoramos en todo momento tanto a la hora del precio como de ajustar vuestras potencias. Al fin y al cabo, todo suma y nuestra finalidad es restar en vuestras facturas.'
          ];
        case '5': // fija 6.1TD
          return [
            'Consigue energía más barata para tu empresa y 100% verde, en Energest nos gusta hacer las cosas simples y por ello no queremos darte mas líos de cabeza con varios precios, descuentos irreales o mantenimientos innecesarios. Si te gusta colaborar con empresas honestas, estás en el sitio adecuado.'
          ];
        default:
          // en caso de id '6', que es la indexada 2.0TD
          return [
            'Paga exactamente lo que has utilizado a final de cada mes.',
            'Con la prefactura sabrás con antelación cuánto pagarás (si no estás de acuerdo lo cambiamos).',
            'Ahorra pagando solo lo que tú necesitas.'
          ];
      }
    },
    tarifaNameFormated() {
      if (this.offer.aliasTarifa) {
        return this.offer.aliasTarifa;
      }

      return this.offer.nombreTarifa;
    },
    priceMontlyClassName() {
      return {
        alone: this.offer.ahorroMensual === null,
        small: String(this.offer.precioMensual.toFixed(2)).length > 5
      };
    },
    savingsMontlyClassName() {
      return {
        small: String(this.offer.ahorroMensual.toFixed(2)).length > 5
      };
    }
  }
};
